// Ionic Variables and Theming. For more info, please see:
// http://ionicframework.com/docs/theming/

/** Ionic CSS Variables **/
:root {
    /** primary **/
    --ion-color-primary: #ffcc00;
    --ion-color-primary-rgb: 255, 204, 0;
    --ion-color-primary-contrast: #000000;
    --ion-color-primary-contrast-rgb: 0, 0, 0;
    --ion-color-primary-shade: #e0b400;
    --ion-color-primary-tint: #ffd11a;

    /** secondary **/
    --ion-color-secondary: #333438;
    --ion-color-secondary-rgb: 51, 52, 56;
    --ion-color-secondary-contrast: #ffffff;
    --ion-color-secondary-contrast-rgb: 255, 255, 255;
    --ion-color-secondary-shade: #2d2e31;
    --ion-color-secondary-tint: #47484c;

    /** tertiary **/
    --ion-color-tertiary: #5260ff;
    --ion-color-tertiary-rgb: 82, 96, 255;
    --ion-color-tertiary-contrast: #ffffff;
    --ion-color-tertiary-contrast-rgb: 255, 255, 255;
    --ion-color-tertiary-shade: #4854e0;
    --ion-color-tertiary-tint: #6370ff;

    /** success **/
    --ion-color-success: #2dd36f;
    --ion-color-success-rgb: 45, 211, 111;
    --ion-color-success-contrast: #ffffff;
    --ion-color-success-contrast-rgb: 255, 255, 255;
    --ion-color-success-shade: #28ba62;
    --ion-color-success-tint: #42d77d;

    /** warning **/
    --ion-color-warning: #f2b35b;
    --ion-color-warning-rgb: 242, 179, 91;
    --ion-color-warning-contrast: #000000;
    --ion-color-warning-contrast-rgb: 0, 0, 0;
    --ion-color-warning-shade: #d59e50;
    --ion-color-warning-tint: #f3bb6b;

    /** danger **/
    --ion-color-danger: #ff4961;
    --ion-color-danger-rgb: 255, 73, 97;
    --ion-color-danger-contrast: #ffffff;
    --ion-color-danger-contrast-rgb: 255, 255, 255;
    --ion-color-danger-shade: #e04055;
    --ion-color-danger-tint: #ff5b71;

    /** dark **/
    --ion-color-dark: #1d1d1b;
    --ion-color-dark-rgb: 29, 29, 27;
    --ion-color-dark-contrast: #ffffff;
    --ion-color-dark-contrast-rgb: 255, 255, 255;
    --ion-color-dark-shade: #1a1a18;
    --ion-color-dark-tint: #343432;

    /** medium **/
    --ion-color-medium: #92949c;
    --ion-color-medium-rgb: 146, 148, 156;
    --ion-color-medium-contrast: #ffffff;
    --ion-color-medium-contrast-rgb: 255, 255, 255;
    --ion-color-medium-shade: #808289;
    --ion-color-medium-tint: #9d9fa6;

    /** light **/
    --ion-color-light: #efefef;
    --ion-color-light-rgb: 239, 239, 239;
    --ion-color-light-contrast: #000000;
    --ion-color-light-contrast-rgb: 0, 0, 0;
    --ion-color-light-shade: #d2d2d2;
    --ion-color-light-tint: #f1f1f1;
}

@media (prefers-color-scheme: dark) {
    /*
   * Dark Colors
   * -------------------------------------------
   */

    body {
        /** primary **/
        --ion-color-primary: #ffcc00;
        --ion-color-primary-rgb: 255, 204, 0;
        --ion-color-primary-contrast: #000000;
        --ion-color-primary-contrast-rgb: 0, 0, 0;
        --ion-color-primary-shade: #e0b400;
        --ion-color-primary-tint: #ffd11a;

        /** secondary **/
        --ion-color-secondary: #333438;
        --ion-color-secondary-rgb: 51, 52, 56;
        --ion-color-secondary-contrast: #ffffff;
        --ion-color-secondary-contrast-rgb: 255, 255, 255;
        --ion-color-secondary-shade: #2d2e31;
        --ion-color-secondary-tint: #47484c;

        /** tertiary **/
        --ion-color-tertiary: #5260ff;
        --ion-color-tertiary-rgb: 82, 96, 255;
        --ion-color-tertiary-contrast: #ffffff;
        --ion-color-tertiary-contrast-rgb: 255, 255, 255;
        --ion-color-tertiary-shade: #4854e0;
        --ion-color-tertiary-tint: #6370ff;

        /** success **/
        --ion-color-success: #2dd36f;
        --ion-color-success-rgb: 45, 211, 111;
        --ion-color-success-contrast: #ffffff;
        --ion-color-success-contrast-rgb: 255, 255, 255;
        --ion-color-success-shade: #28ba62;
        --ion-color-success-tint: #42d77d;

        /** warning **/
        --ion-color-warning: #f2b35b;
        --ion-color-warning-rgb: 242, 179, 91;
        --ion-color-warning-contrast: #000000;
        --ion-color-warning-contrast-rgb: 0, 0, 0;
        --ion-color-warning-shade: #d59e50;
        --ion-color-warning-tint: #f3bb6b;

        /** danger **/
        --ion-color-danger: #ff4961;
        --ion-color-danger-rgb: 255, 73, 97;
        --ion-color-danger-contrast: #ffffff;
        --ion-color-danger-contrast-rgb: 255, 255, 255;
        --ion-color-danger-shade: #e04055;
        --ion-color-danger-tint: #ff5b71;

        /** dark **/
        --ion-color-dark: #efefef;
        --ion-color-dark-rgb: 239, 239, 239;
        --ion-color-dark-contrast: #000000;
        --ion-color-dark-contrast-rgb: 0, 0, 0;
        --ion-color-dark-shade: #d2d2d2;
        --ion-color-dark-tint: #f1f1f1;

        /** medium **/
        --ion-color-medium: #92949c;
        --ion-color-medium-rgb: 146, 148, 156;
        --ion-color-medium-contrast: #ffffff;
        --ion-color-medium-contrast-rgb: 255, 255, 255;
        --ion-color-medium-shade: #808289;
        --ion-color-medium-tint: #9d9fa6;

        /** light **/
        --ion-color-light: #1d1d1b;
        --ion-color-light-rgb: 29, 29, 27;
        --ion-color-light-contrast: #ffffff;
        --ion-color-light-contrast-rgb: 255, 255, 255;
        --ion-color-light-shade: #1a1a18;
        --ion-color-light-tint: #343432;
    }

    /*
   * iOS Dark Theme
   * -------------------------------------------
   */

    .ios body {
        /** background & fonts **/
        --ion-background-color: #1d1d1b;
        --ion-background-color-rgb: 29, 29, 27;

        --ion-text-color: #ffffff;
        --ion-text-color-rgb: 255, 255, 255;

        --ion-color-step-50: #282826;
        --ion-color-step-100: #343432;
        --ion-color-step-150: #3f3f3d;
        --ion-color-step-200: #4a4a49;
        --ion-color-step-250: #565654;
        --ion-color-step-300: #61615f;
        --ion-color-step-350: #6c6c6b;
        --ion-color-step-400: #777776;
        --ion-color-step-450: #838382;
        --ion-color-step-500: #8e8e8d;
        --ion-color-step-550: #999998;
        --ion-color-step-600: #a5a5a4;
        --ion-color-step-650: #b0b0af;
        --ion-color-step-700: #bbbbbb;
        --ion-color-step-750: #c7c7c6;
        --ion-color-step-800: #d2d2d1;
        --ion-color-step-850: #dddddd;
        --ion-color-step-900: #e8e8e8;
        --ion-color-step-950: #f4f4f4;
    }

    .ios ion-modal {
        --ion-background-color: var(--ion-color-step-100);
        --ion-toolbar-background: var(--ion-color-step-150);
        --ion-toolbar-border-color: var(--ion-color-step-250);
    }

    /*
   * Material Design Dark Theme
   * -------------------------------------------
   */

    .md body {
        --ion-background-color: #1d1d1b;
        --ion-background-color-rgb: 29, 29, 27;

        --ion-text-color: #ffffff;
        --ion-text-color-rgb: 255, 255, 255;

        --ion-border-color: #222222;

        --ion-color-step-50: #282826;
        --ion-color-step-100: #343432;
        --ion-color-step-150: #3f3f3d;
        --ion-color-step-200: #4a4a49;
        --ion-color-step-250: #565654;
        --ion-color-step-300: #61615f;
        --ion-color-step-350: #6c6c6b;
        --ion-color-step-400: #777776;
        --ion-color-step-450: #838382;
        --ion-color-step-500: #8e8e8d;
        --ion-color-step-550: #999998;
        --ion-color-step-600: #a5a5a4;
        --ion-color-step-650: #b0b0af;
        --ion-color-step-700: #bbbbbb;
        --ion-color-step-750: #c7c7c6;
        --ion-color-step-800: #d2d2d1;
        --ion-color-step-850: #dddddd;
        --ion-color-step-900: #e8e8e8;
        --ion-color-step-950: #f4f4f4;
    }
}
